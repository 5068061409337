import React, { useState, useEffect } from 'react';
import * as S from './CookieContainer.styles';
import { Builder } from '@builder.io/react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const CookieContainer = props => {
  const {
    cookieKey,
    cookieValue,
    children,
    attributes,
    checkUrlParams
  } = props;

  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!checked) {
      if (checkUrlParams) {
        let queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get(cookieKey)) {
          cookies.set(cookieKey, queryParams.get(cookieKey) ?? '');
        }
      }
      if (Object.keys(cookies.getAll()).includes(cookieKey)) {
        if (cookies.get(cookieKey) === cookieValue) {
          setShow(true);
        }
      } else {
        if (`` === cookieValue) {
          setShow(true);
        }
      }
    }
    return () => {
      setChecked(true);
    };
  }, [setShow, cookieKey, cookieValue, checked, setChecked, checkUrlParams]);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const Container = () => {
    return (
      <S.Container
        {...attributes}
        layerId={`${cookieKey}=${cookieValue}`}
        editing={Builder.isEditing}
      >
        {children || noChildren()}
      </S.Container>
    );
  };

  if (show || Builder.isEditing) {
    return <Container />;
  } else {
    return ``;
  }
};

export default CookieContainer;
